.clock {
    width: 150px;
    height: 150px;
    border: 1px solid hsl(0, 0%, 100%);
    border-radius: 50%;
    position: relative;
    background: #000;
    margin: auto;
  }
  
  .face {
    position: relative;
    width: 100%;
    height: 100%;
    /* background: #000; */
  }
  .cap {
    position: relative;
    width: 20%;
    height: 100%;
    background: #000;
    border-radius: 50%;
  }
  
  .hand {
    width: 50%;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 0%;
    transform-origin: 100%;
    transform: rotate(90deg);
    transition: all 0.05s cubic-bezier(0.4, 2.3, 0.3, 1);
  }
  
  .hour {
    height: 6px;
    background: #8a8d8e8e;
  }
  
  .minute {
    height: 6px;
    background: #8a8d8e8e;
  }
  
  .second {
    height: 2px;
    background: #ffff;
  }
  